import React from 'react'
import style from 'styled-components'
import { Link } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/layout'

const Info = style.h1`
  padding: 1em;
  color: dimgray;
`

const Thanks = () => (
  <Layout colorful={true}>
    <SEO title="Thanks" />
    <Info>Grazie mille per la tua donazione! ✌️</Info>
    <Link to="/">Torna alla homepage!</Link>
  </Layout>
)

export default Thanks
